import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Euroleague() {
  const [startRound, setStartRound] = useState('');
  const [endRound, setEndRound] = useState('');
  const [message, setMessage] = useState('');
  const [seasonYear, setSeasonYear] = useState('');
  const [gameType, setGameType] = useState('Regular Season');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming your backend endpoint for scraping Euroleague data is /scrape-euroleague
      const response = await axios.post(process.env.REACT_APP_API_URL + '/scrape-euroleague', { startRound, endRound, seasonYear, gameType });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2>Euroleague Data Scraping</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Start Round</Form.Label>
              <Form.Control
                type="number"
                value={startRound}
                onChange={(e) => setStartRound(e.target.value)}
                placeholder="Enter start round"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Round</Form.Label>
              <Form.Control
                type="number"
                value={endRound}
                onChange={(e) => setEndRound(e.target.value)}
                placeholder="Enter end round"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Season Year</Form.Label>
              <Form.Control
                type="number"
                value={seasonYear}
                onChange={(e) => setSeasonYear(e.target.value)}
                placeholder="Enter season year"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Game Type</Form.Label>
              <Form.Control as="select" value={gameType} onChange={(e) => setGameType(e.target.value)}>
                <option value="Regular Season">Regular Season</option>
                <option value="Playoffs">Playoffs</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              Scrape Data
            </Button>
          </Form>
          {message && <div className="mt-3">{message}</div>}
        </Col>
      </Row>
    </Container>
  );
}

export default Euroleague;
