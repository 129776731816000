import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Table, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function CompetitionStats() {
  const [teamStats, setTeamStats] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { competitionId } = useParams();
  const navigate = useNavigate();



  const fetchTeamStats = useCallback(async (start, end) => {
    const response = await axios(process.env.REACT_APP_API_URL + `/competition-stats/${competitionId}?startDate=${start}&endDate=${end}`);
    setTeamStats(response.data);
  }, [competitionId]); // teamId is a dependency of fetchPlayerStats

  useEffect(() => {
    fetchTeamStats(startDate, endDate);
  }, [competitionId, startDate, endDate, fetchTeamStats]); // Now includes fetchPlayerStats as a dependency

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchTeamStats(startDate, endDate);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={12} lg={12}>
          <Card>
            <Card.Header className="text-center">
              <h2>Competition Team Stats</h2>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={6}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="mt-3">Filter Stats</Button>
              </Form>
              <Table striped bordered hover size="sm" responsive="sm">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Avg Goals</th>
                    <th>Avg Assists</th>
                    <th>Avg Red Cards</th>
                    <th>Avg Yellow Cards</th>
                    <th>Avg Corners Won</th>
                    <th>Avg Shots</th>
                    <th>Avg Shots on Target</th>
                    <th>Avg Blocked Shots</th>
                    <th>Avg Passes</th>
                    <th>Avg Crosses</th>
                    <th>Avg Tackles</th>
                    <th>Avg Offsides</th>
                    <th>Avg Fouls Conceded</th>
                    <th>Avg Fouls Won</th>
                  </tr>
                </thead>
                <tbody>
                  {teamStats.map((team, index) => (
                    <tr key={index}>
                      <td style={{ cursor: 'pointer', color: 'darkgreen', fontWeight: 'bold' }} onClick={() => navigate(`/team/${team.team_id}`)}>{team.team_name}</td>
                      <td>{team.avg_goals}</td>
                      <td>{team.avg_assists}</td>
                      <td>{team.avg_red_cards}</td>
                      <td>{team.avg_yellow_cards}</td>
                      <td>{team.avg_corners_won}</td>
                      <td>{team.avg_shots}</td>
                      <td>{team.avg_shots_on_target}</td>
                      <td>{team.avg_blocked_shots}</td>
                      <td>{team.avg_passes}</td>
                      <td>{team.avg_crosses}</td>
                      <td>{team.avg_tackles}</td>
                      <td>{team.avg_offsides}</td>
                      <td>{team.avg_fouls_conceded}</td>
                      <td>{team.avg_fouls_won}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CompetitionStats;
