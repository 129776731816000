import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function NBA() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [message, setMessage] = useState('');
  const [gameType, setGameType] = useState('Regular Season');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Format the dates to "YYYY-MM-DD" before sending
      const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
      const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

      const response = await axios.post(process.env.REACT_APP_API_URL + '/scrape-nba', {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        gameType, // Assuming you want to send this as well
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2>NBA Data Scraping</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Game Type</Form.Label>
              <Form.Control as="select" value={gameType} onChange={(e) => setGameType(e.target.value)}>
                <option value="Regular Season">Regular Season</option>
                <option value="Playoffs">Playoffs</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              Scrape Data
            </Button>
          </Form>
          {message && <div className="mt-3">{message}</div>}
        </Col>
      </Row>
    </Container>
  );
}

export default NBA;
