import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useState } from 'react';

import Euroleague from './components/Euroleague';
import NBA from './components/NBA';
import Home from './components/Home';
import Opta from './components/Opta';
import Stats from './components/Stats';
import CompetitionStats from './components/CompetitionStats';
import TeamStats from './components/TeamStats';
import PlayerStatsFilters from './components/PlayerStatsFilters';
import TeamStatsFilters from './components/TeamStatsFilters';
import Eurocup from './components/Eurocup';
import Login from './components/Login';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isProd = process.env.REACT_APP_IS_PROD === 'true';  // Get environment variable

  return (
    <Router>
      <div className="App">
        {/* Navigation Bar */}
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <ul className="navbar-nav mr-auto">
            {/* Conditionally render based on authentication and production status */}
            {isAuthenticated && (
              <>
                <li className="nav-item">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                {!isProd && (
                  <>
                    <li className="nav-item">
                      <Link to="/euroleague" className="nav-link">Euroleague</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/nba" className="nav-link">NBA</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/opta" className="nav-link">Opta</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/eurocup" className="nav-link">Eurocup</Link>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Link to="/stats" className="nav-link">Stats</Link>
                </li>
                <li className="nav-item">
                  <Link to="/playerstatsfilters" className="nav-link">PlayerStatsFilters</Link>
                </li>
                <li className="nav-item">
                  <Link to="/teamstatsfilters" className="nav-link">TeamStatsFilters</Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        {/* Routes */}
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
              <Route path="/stats" element={<Stats />} />
              <Route path="/competitions/:competitionId" element={<CompetitionStats />} />
              <Route path="/team/:teamId" element={<TeamStats />} />
              <Route path="/playerstatsfilters" element={<PlayerStatsFilters />} />
              <Route path="/teamstatsfilters" element={<TeamStatsFilters />} />
              {isProd ? null : (
                <>
                  <Route path="/eurocup" element={<Eurocup />} />
                  <Route path="/euroleague" element={<Euroleague />} />
                  <Route path="/nba" element={<NBA />} />
                  <Route path="/opta" element={<Opta />} />
                </>
              )}
              <Route path="/" element={<Home />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
