import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Stats() {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountriesWithCompetitions = async () => {
      // Ensure this URL matches your actual API endpoint
      const result = await axios(process.env.REACT_APP_API_URL + '/countries-with-competitions');
      setCountries(result.data);
    };

    fetchCountriesWithCompetitions();
  }, []);

  return (
    <Container className="mt-3" style={{ opacity: 0.95 }}>
      <Accordion defaultActiveKey="0">
        {countries.map((country, index) => (
          <Accordion.Item eventKey={String(index)} key={index}>
            <Accordion.Header>{country.country_name}</Accordion.Header>
            <Accordion.Body>
              {country.competitions.map((competition) => (
                <div key={competition.competition_id} 
                     onClick={() => navigate(`/competitions/${competition.competition_id}`)} 
                     style={{ cursor: 'pointer', marginBottom: '10px' }}>
                  {competition.competition_name}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}

export default Stats;
