import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';
import { ThreeDots } from 'react-loader-spinner';
import qs from 'qs';
import { debounce } from 'lodash';

function PlayerStatsFilters() {
    const [playersTeams, setPlayersTeams] = useState([]);
    const [teams, setTeams] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [opponentTeamId, setOpponentTeamId] = useState('');
    const [homeAway, setHomeAway] = useState('');
    const [competitionId, setCompetitionId] = useState('');
    const [half, setHalf] = useState('0');
    const [loading, setLoading] = useState(false);
    const [playerStats, setPlayerStats] = useState([]);
    const [playerTeamOptions, setPlayerTeamOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black'
        }),
        control: (provided) => ({
            ...provided,
            width: '100%',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        }
    }

    useEffect(() => {
        const fetchInitialData = async () => {
            const playerTeamsRes = await axios(process.env.REACT_APP_API_URL + '/players-teams');
            setPlayersTeams(playerTeamsRes.data);
            const teamsRes = await axios(process.env.REACT_APP_API_URL + '/teams');
            setTeams(teamsRes.data);
            const competitionsRes = await axios(process.env.REACT_APP_API_URL + '/competitions');
            setCompetitions(competitionsRes.data);
            setPlayerTeamOptions(playerTeamsRes.data.map(({ player_id, player_name, team_name }) => ({
                value: player_id,
                label: `${player_name} - ${team_name}`
            })));
        };
        fetchInitialData();
    }, []);

    // Memoized filter function to reduce unnecessary re-renders
    const filterOptions = useCallback(debounce((query) => {
        const filteredPlayers = playersTeams.filter(({ player_name, team_name }) =>
            `${player_name} - ${team_name}`.toLowerCase().includes(query.toLowerCase())
        );
        setPlayerTeamOptions(filteredPlayers.map(({ player_id, player_name, team_name }) => ({
            value: player_id,
            label: `${player_name} - ${team_name}`
        })));
    }, 300), [playersTeams]);

    useEffect(() => {
        filterOptions(searchQuery);
    }, [searchQuery, filterOptions]);

    const handlePlayerSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Prepare playerIds for the request
        const playerIds = selectedPlayers.map(({ value }) => value);

        // Adjusted request with qs
        const response = await axios.get(process.env.REACT_APP_API_URL + '/player-stats', {
            params: {
                playerIds: playerIds,
                startDate,
                endDate,
                opponentTeamId,
                half,
                homeAway,
                competitionId
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        setPlayerStats(response.data);
        setLoading(false);
    };

    return (
        <div>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="playerTeamSelect">
                                <Form.Label>Player-Team</Form.Label>
                                <input
                                    type="text"
                                    placeholder="Search Player-Team"
                                    value={searchQuery}
                                    onChange={handlePlayerSearchChange}
                                    className="form-control"
                                />
                                <MultiSelect
                                    options={playerTeamOptions}
                                    value={selectedPlayers}
                                    onChange={setSelectedPlayers}
                                    labelledBy={"Select players"}
                                    hasSelectAll={false}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="opponentTeamSelect">
                                <Form.Label>Opponent Team</Form.Label>
                                <Select
                                    styles={customStyles}
                                    options={teams.map(team => ({ value: team.team_id, label: team.name }))}
                                    onChange={option => setOpponentTeamId(option.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="homeAwaySelect">
                                <Form.Label>Home/Away</Form.Label>
                                <Form.Control as="select" value={homeAway} onChange={e => setHomeAway(e.target.value)}>
                                    <option value="">Any</option>
                                    <option value="home">Home</option>
                                    <option value="away">Away</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="competitionSelect">
                                <Form.Label>Competition</Form.Label>
                                <Select
                                    styles={customStyles}
                                    options={competitions.map(({ competition_id, name }) => ({
                                        value: competition_id,
                                        label: name
                                    }))}
                                    onChange={option => setCompetitionId(option.value)}
                                    value={competitions.find(option => option.value === competitionId)}
                                    placeholder="Select a Competition"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="endDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="halfSelect">
                                <Form.Label>Half</Form.Label>
                                <Form.Control as="select" value={half} onChange={e => setHalf(e.target.value)}>
                                    <option value="0">Full</option>
                                    <option value="1">First Half</option>
                                    <option value="2">Second Half</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" type="submit">Fetch Stats</Button>
                </Form>
            </Container>

            {loading ? (
                <div className="text-center mt-3">
                    <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
            ) : playerStats.length > 0 ? (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <Table striped bordered hover size="sm" className="mt-3">
                        <thead>
                            <tr>
                                <th>Player Name - Team Name</th>
                                <th>Avg Goals</th>
                                <th>Avg Assists</th>
                                <th>Avg Red Cards</th>
                                <th>Avg Yellow Cards</th>
                                <th>Avg Corners Won</th>
                                <th>Avg Shots</th>
                                <th>Avg Shots on Target</th>
                                <th>Avg Blocked Shots</th>
                                <th>Avg Passes</th>
                                <th>Avg Crosses</th>
                                <th>Avg Tackles</th>
                                <th>Avg Offsides</th>
                                <th>Avg Fouls Conceded</th>
                                <th>Avg Fouls Won</th>
                                <th>Sum Goals</th>
                                <th>Sum Assists</th>
                                <th>Sum Red Cards</th>
                                <th>Sum Yellow Cards</th>
                                <th>Sum Corners Won</th>
                                <th>Sum Shots</th>
                                <th>Sum Shots on Target</th>
                                <th>Sum Blocked Shots</th>
                                <th>Sum Passes</th>
                                <th>Sum Crosses</th>
                                <th>Sum Tackles</th>
                                <th>Sum Offsides</th>
                                <th>Sum Fouls Conceded</th>
                                <th>Sum Fouls Won</th>
                            </tr>
                        </thead>
                        <tbody>
                            {playerStats.map(stat => (
                                <tr key={stat.player_id}>
                                    <td>{stat.player_name} - {stat.team_name}</td>
                                    <td>{stat.avg_goals}</td>
                                    <td>{stat.avg_assists}</td>
                                    <td>{stat.avg_red_cards}</td>
                                    <td>{stat.avg_yellow_cards}</td>
                                    <td>{stat.avg_corners_won}</td>
                                    <td>{stat.avg_shots}</td>
                                    <td>{stat.avg_shots_on_target}</td>
                                    <td>{stat.avg_blocked_shots}</td>
                                    <td>{stat.avg_passes}</td>
                                    <td>{stat.avg_crosses}</td>
                                    <td>{stat.avg_tackles}</td>
                                    <td>{stat.avg_offsides}</td>
                                    <td>{stat.avg_fouls_conceded}</td>
                                    <td>{stat.avg_fouls_won}</td>
                                    <td>{stat.sum_goals}</td>
                                    <td>{stat.sum_assists}</td>
                                    <td>{stat.sum_red_cards}</td>
                                    <td>{stat.sum_yellow_cards}</td>
                                    <td>{stat.sum_corners_won}</td>
                                    <td>{stat.sum_shots}</td>
                                    <td>{stat.sum_shots_on_target}</td>
                                    <td>{stat.sum_blocked_shots}</td>
                                    <td>{stat.sum_passes}</td>
                                    <td>{stat.sum_crosses}</td>
                                    <td>{stat.sum_tackles}</td>
                                    <td>{stat.sum_offsides}</td>
                                    <td>{stat.sum_fouls_conceded}</td>
                                    <td>{stat.sum_fouls_won}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </motion.div>
            ) : null}
        </div>
    );
}

export default PlayerStatsFilters;
