import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Home() {
  const [gameLink, setGameLink] = useState('');
  const [leagueType, setLeagueType] = useState('NBA');
  const [gameType, setGameType] = useState('Regular Season');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming your backend endpoint for processing a single game link is /process-game-link
      const response = await axios.post(process.env.REACT_APP_API_URL + '/process-game-link', { gameLink, leagueType, gameType });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2>Process Single Game Link</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Game Link</Form.Label>
              <Form.Control
                type="text"
                value={gameLink}
                onChange={(e) => setGameLink(e.target.value)}
                placeholder="Enter game link"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Game Type</Form.Label>
              <Form.Control as="select" value={gameType} onChange={(e) => setGameType(e.target.value)}>
                <option value="Regular Season">Regular Season</option>
                <option value="Playoffs">Playoffs</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>League Type</Form.Label>
              <Form.Control as="select" value={leagueType} onChange={(e) => setLeagueType(e.target.value)}>
                <option value="NBA">NBA</option>
                <option value="Euroleague">Euroleague</option>
                <option value="Eurocup">Eurocup</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              Process Game Link
            </Button>
          </Form>
          {message && <div className="mt-3">{message}</div>}
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
