import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Opta() {
  const [gameLink, setGameLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [botMessage, setBotMessage] = useState('');
  const [singleMessage, setSingleMessage] = useState('');

  const handleBotSubmit = async (e) => {
    e.preventDefault();
    try {
      // Format the dates to "YYYY-MM-DD" before sending
      const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
      const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

      const response = await axios.post(process.env.REACT_APP_API_URL + '/scrape-opta', {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
      setBotMessage(response.data.message);
    } catch (error) {
      setBotMessage('Error: ' + error.message);
    }
  };

  const handleSingleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming your backend endpoint for processing a single game link is /process-game-link
      const response = await axios.post(process.env.REACT_APP_API_URL + '/process-opta-game-link', { gameLink });
      setSingleMessage(response.data.message);
    } catch (error) {
      setSingleMessage('Error: ' + error.message);
    }
  };

  return (
    <div>
      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h2>Opta Bot Data Scraping</h2>
            <Form onSubmit={handleBotSubmit}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Scrape Data
              </Button>
            </Form>
            {botMessage && <div className="mt-3">{botMessage}</div>}
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h2>Opta Single-Game Scrape</h2>
            <Form onSubmit={handleSingleSubmit}>
              <Form.Group>
                <Form.Label>Game Link</Form.Label>
                <Form.Control
                  type="text"
                  value={gameLink}
                  onChange={(e) => setGameLink(e.target.value)}
                  placeholder="Enter game link"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Process Game Link
              </Button>
            </Form>
            {singleMessage && <div className="mt-3">{singleMessage}</div>}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Opta;
