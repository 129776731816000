import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Table, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

function TeamStats() {
  const [playerStats, setPlayerStats] = useState([]);
  const [teamStats, setTeamStats] = useState([]); // Change to an array for multiple competitions
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { teamId } = useParams();

  const fetchPlayerStats = useCallback(async (start, end) => {
    const response = await axios(process.env.REACT_APP_API_URL + `/player-stats/${teamId}?startDate=${start}&endDate=${end}`);
    setPlayerStats(response.data);
  }, [teamId]);

  const fetchTeamStats = useCallback(async (start, end) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/team-stats/${teamId}?startDate=${start}&endDate=${end}`);
    setTeamStats(response.data); // Response should be an array for multiple competitions
  }, [teamId]);

  useEffect(() => {
    fetchTeamStats(startDate, endDate);
    fetchPlayerStats(startDate, endDate);
  }, [teamId, startDate, endDate, fetchPlayerStats, fetchTeamStats]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchTeamStats(startDate, endDate);
    fetchPlayerStats(startDate, endDate);
  };

  return (
    <Container >
      <Row className="justify-content-center">
        <Col xs={12} md={12} lg={12}>
          <Card>
            <Card.Header className="text-center">
              <h2>Player Stats</h2>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={6}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="mt-3">Filter Stats</Button>
              </Form>
              <Table striped bordered hover size="sm" className="mt-3">
                <thead>
                  <tr>
                    <th>Player Name</th>
                    <th>Avg Goals</th>
                    <th>Avg Assists</th>
                    <th>Avg Red Cards</th>
                    <th>Avg Yellow Cards</th>
                    <th>Avg Corners Won</th>
                    <th>Avg Shots</th>
                    <th>Avg Shots on Target</th>
                    <th>Avg Blocked Shots</th>
                    <th>Avg Passes</th>
                    <th>Avg Crosses</th>
                    <th>Avg Tackles</th>
                    <th>Avg Offsides</th>
                    <th>Avg Fouls Conceded</th>
                    <th>Avg Fouls Won</th>
                  </tr>
                </thead>
                <tbody>
                  {playerStats.map((stat, index) => (
                    <tr key={index}>
                      <td style={{ color: 'darkgreen', fontWeight: 'bold' }}>{stat.player_name}</td>
                      <td>{stat.avg_goals}</td>
                      <td>{stat.avg_assists}</td>
                      <td>{stat.avg_red_cards}</td>
                      <td>{stat.avg_yellow_cards}</td>
                      <td>{stat.avg_corners_won}</td>
                      <td>{stat.avg_shots}</td>
                      <td>{stat.avg_shots_on_target}</td>
                      <td>{stat.avg_blocked_shots}</td>
                      <td>{stat.avg_passes}</td>
                      <td>{stat.avg_crosses}</td>
                      <td>{stat.avg_tackles}</td>
                      <td>{stat.avg_offsides}</td>
                      <td>{stat.avg_fouls_conceded}</td>
                      <td>{stat.avg_fouls_won}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        {/* New Column for Selected Team Stats - Now supports multiple competitions */}
        <Col xs={12} md={4} lg={4}>
          {teamStats.map((stat, index) => (
            <Card key={index} className="mb-3">
              <Card.Header className="text-center">
                <h3>{stat.competition_name}</h3>
              </Card.Header>
              <Card.Body>
                <p><strong>Team Name:</strong> {stat.team_name}</p>
                <p><strong>Wins:</strong> {stat.wins}</p>
                <p><strong>Losses:</strong> {stat.losses}</p>
                <p><strong>Draws:</strong> {stat.draws}</p>
                <p><strong>Total Games:</strong> {stat.total_games}</p>
                <p><strong>Win Percentage:</strong> {stat.win_percentage.toFixed(2)}%</p>
                <p><strong>Loss Percentage:</strong> {stat.loss_percentage.toFixed(2)}%</p>
                <p><strong>Draw Percentage:</strong> {stat.draw_percentage.toFixed(2)}%</p>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default TeamStats;
